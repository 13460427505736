"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
//@ts-ignore
const calculator_js_1 = require("./calculator.js");
class ScientificcalculatorCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.history = [];
    }
    do(operation) {
        console.log('do');
        const result = (0, calculator_js_1.calc)(operation);
        if (result != undefined)
            this.history = [
                ...result
            ];
    }
    $onInit() {
        window.onkeydown = calculator_js_1.calcKeydown;
        (0, rxjs_1.fromEvent)(document, 'keydown').pipe(
        // 61 - firefox
        (0, operators_1.filter)((a) => (!a.shiftKey && 187 == a.keyCode) || 13 == a.keyCode || (!a.shiftKey && 61 == a.keyCode)), (0, operators_1.tap)((a) => {
            console.log(a.keyCode);
            this.$timeout(() => {
                this.do('=');
            });
        })).subscribe();
    }
    input($event, html, calculatioCInputs) {
        document.getElementById('sciInPut').innerHTML = html;
        document.getElementById('sciOutPut').innerHTML = '&nbsp';
        (0, calculator_js_1.setCalculatioCInputs)(calculatioCInputs || []);
    }
}
ScientificcalculatorCtrl.$inject = ['$scope', '$timeout', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameScientificcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: ScientificcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('scientificcalculator/');
    }]);
