window.calculatioDegreeRadians = "degree";
var calculatioCInputs = [0],
    calculatioHistory = [],
    calculatioHistoryShow = 10,
    calculatioAccuracy = 12,
    calculatioNew = !0,
    calculatioMemoryV = 0,
    calculatioScreenV = 0,
    calculatioPrevAns = 0;

var i, A, B, C, TP, F, calculatioRZOut;

function calculatioFinal(r) {
    var a = calculatioCombineNumbers(),
        c = [],
        e = [],
        o = 0;
    for (i = 0; i < a.length; i++)
        (A = a[i]),
            (B = a[i - 1]),
        (isNaN(A) && "sin" != A && "cos" != A && "tan" != A && "asin" != A && "acos" != A && "atan" != A && "ex" != A && "10x" != A && "ln" != A && "log" != A && "3x" != A && "sqrt" != A && "(" != A && "pi" != A && "e" != A) ||
        (isNaN(B) && "1/x" != B && "pc" != B && "n!" != B && "x3" != B && "x2" != B && ")" != B && "pi" != B && "e" != B) ||
        (e.push("*"), c.push("*")),
            "sin" == A || "cos" == A || "tan" == A || "asin" == A || "acos" == A || "atan" == A || "ln" == A || "log" == A || "3x" == A || "sqrt" == A
                ? (e.push(A), e.push("("), c.push(A), c.push("("), o++)
                : ("(" == A && o++, ")" == A && o--, e.push(A), c.push(A));
    if (0 < o) for (i = 0; i < o; i++) c.push("<b>)</b>"), e.push(")");
    2 < e.length &&
    "0" == e[0] + "" &&
    "*" == e[1] &&
    ((A = e[2]),
    ("sin" != A && "cos" != A && "tan" != A && "asin" != A && "acos" != A && "atan" != A && "ex" != A && "10x" != A && "ln" != A && "log" != A && "(" != A && "pi" != A && "e" != A && "3x" != A && "sqrt" != A) ||
    ((e = e.slice(2)), (c = c.slice(2))));
    for (var t = c, l = [], s = 0, n = 0; -1 < t.indexOf(")") || -1 < t.indexOf("<b>)</b>");) {
        s = t.indexOf(")");
        var u = t.indexOf("<b>)</b>");
        -1 < s ? -1 < u && u < s && (s = u) : (s = u), (t = calculatioArrayProc(t, (n = (l = t.slice(0, s + 1)).lastIndexOf("(")), s + 1, calculatioFormatDisplay((l = l.slice(n)))));
    }
    var y = calculatioFormatDisplay(t);
    for (t = e, l = [], n = s = 0; -1 < t.indexOf(")");) (s = t.indexOf(")")), (t = calculatioArrayProc(t, (n = (l = t.slice(0, s + 1)).lastIndexOf("(")), s + 1, calculatioCalcResult((l = l.slice(n)))));
    var p = calculatioCalcResult(t);


    return (
        isNaN(p) && ((p = "Error"), 1 != r && (p = "")),
            (document.getElementById("sciOutPut").innerHTML = calculatioFmt(p) || '&nbsp'),
        1 == r && ((y = y.replace(/<b>/g, "").replace(/<\/b>/g, "")), 1 < a.length && (calculatioHistory.unshift([y, calculatioFmt(p), [...calculatioCInputs], p]), calculatioHistory.length > calculatioHistoryShow && calculatioHistory.splice(calculatioHistory.length-1, 1)), (y += " =")),
            (document.getElementById("sciInPut").innerHTML = y),
            p
    );
}

function calculatioFormatDisplay(r) {
    for (var a = r; -1 < a.indexOf("EXP");) {
        var c = a.indexOf("EXP");
        a =
            "neg" == a[c + 1]
                ? calculatioArrayProc(a, c, c + 2, " &#215; 10<sup>-</sup>")
                : isNaN(a[c + 1])
                ? calculatioArrayProc(a, c, c + 1, " &#215; 10<sup><b>&#9634;</b></sup>")
                : calculatioArrayProc(a, c, c + 2, " &#215; 10<sup>" + a[c + 1] + "</sup>");
    }
    for (o = 0; o < a.length;)
        (A = a[o]),
            "pi" == A
                ? (a[o] = "&pi;")
                : "e" == A
                ? (a[o] = "e")
                : ("sin" != A && "cos" != A && "tan" != A && "asin" != A && "acos" != A && "atan" != A && "3x" != A && "sqrt" != A && "ln" != A && "log" != A) ||
                ("asin" == A ? (a[o] = "arcsin") : "acos" == A ? (a[o] = "arccos") : "atan" == A ? (a[o] = "arctan") : "3x" == A ? (a[o] = "<sup>3</sup>&#8730;") : "sqrt" == A && (a[o] = "&#8730;"),
                    (a[o] = a[o] + a[o + 1]),
                    a.splice(o + 1, 1)),
            o++;
    for (var e = a.length, o = 0; o < e;)
        if (((A = a[o]), "pc" == A)) (a[o - 1] = a[o - 1] + "%"), a.splice(o, 1), e--;
        else if ("n!" == A) !isNaN(a[o - 1]) && a[o - 1] < 0 ? (a[o - 1] = "(" + a[o - 1] + ")!") : (a[o - 1] = a[o - 1] + "!"), a.splice(o, 1), e--;
        else if ("x3" == A)
            6 < (t = a[o - 1] + "").length && (t = t.substr(t.length - 6)),
                "</sup>" == t || "-" == (a[o - 1] + "").substring(0, 1) ? (a[o - 1] = "(" + a[o - 1] + ")<sup>3</sup>") : (a[o - 1] = a[o - 1] + "<sup>3</sup>"),
                a.splice(o, 1),
                e--;
        else if ("x2" == A) {
            var t;
            6 < (t = a[o - 1] + "").length && (t = t.substr(t.length - 6)),
                "</sup>" == t || "-" == (a[o - 1] + "").substring(0, 1) ? (a[o - 1] = "(" + a[o - 1] + ")<sup>2</sup>") : (a[o - 1] = a[o - 1] + "<sup>2</sup>"),
                a.splice(o, 1),
                e--;
        } else "1/x" == A ? ((a[o - 1] = "(1/" + a[o - 1] + ")"), a.splice(o, 1), e--) : o++;
    for (; -1 < a.lastIndexOf("pow") || -1 < a.lastIndexOf("apow") || -1 < a.lastIndexOf("ex") || -1 < a.lastIndexOf("10x");) {
        c = a.lastIndexOf("pow");
        var i = a.lastIndexOf("apow");
        if ((c < i && (c = i), c < (i = a.lastIndexOf("ex")) && (c = i), c < (i = a.lastIndexOf("10x")) && (c = i), "pow" == a[c])) {
            var l = "";
            "neg" == a[c + 1] && ((l = "-"), a.splice(c + 1, 1));
            var s = "",
                n = "";
            "-" == (a[c - 1] + "").substring(0, 1) && ((s = "("), (n = ")")),
                a[c + 1] && "" != a[c + 1] && ")" != a[c + 1] && "<b>)</b>" != a[c + 1]
                    ? ((a[c - 1] = s + a[c - 1] + n + "<sup>" + l + a[c + 1] + "</sup>"), a.splice(c, 2))
                    : ((a[c - 1] = "-" == l ? s + a[c - 1] + n + "<sup>-</sup>" : s + a[c - 1] + n + "<sup><b>&#9634;</b></sup>"), a.splice(c, 1));
        } else
            "apow" == a[c]
                ? ((l = ""),
                "neg" == a[c + 1] && ((l = "-"), a.splice(c + 1, 1)),
                    a[c + 1] && "" != a[c + 1] && ")" != a[c + 1] && "<b>)</b>" != a[c + 1]
                        ? ((a[c - 1] = "<sup>" + l + a[c + 1] + "</sup>&#8730;" + a[c - 1]), a.splice(c, 2))
                        : ((a[c - 1] = "-" == l ? "<sup>-</sup>&#8730;" + a[c - 1] : "<sup><b>&#9634;</b></sup>&#8730;" + a[c - 1]), a.splice(c, 1)))
                : "ex" == a[c]
                ? ((l = ""),
                "neg" == a[c + 1] && ((l = "-"), a.splice(c + 1, 1)),
                    a[c + 1] && "" != a[c + 1] && ")" != a[c + 1] && "<b>)</b>" != a[c + 1] ? ((a[c] = "e<sup>" + l + a[c + 1] + "</sup>"), a.splice(c + 1, 1)) : (a[c] = "-" == l ? "e<sup>-</sup>" : "e<sup><b>&#9634;</b></sup>"))
                : "10x" == a[c] &&
                ((l = ""),
                "neg" == a[c + 1] && ((l = "-"), a.splice(c + 1, 1)),
                    a[c + 1] && "" != a[c + 1] && ")" != a[c + 1] && "<b>)</b>" != a[c + 1] ? ((a[c] = "10<sup>" + l + a[c + 1] + "</sup>"), a.splice(c + 1, 1)) : (a[c] = "-" == l ? "10<sup>-</sup>" : "10<sup><b>&#9634;</b></sup>"));
    }
    var u = "";
    for (o = 0; o < a.length; o++) (A = a[o]), isNaN(A) ? ("*" == A ? (u += " &#215; ") : "/" == A ? (u += " &#247; ") : "+" == A ? (u += " + ") : "-" == A ? (u += " &minus; ") : "neg" == A ? (u += " -") : (u += A)) : (u += A);

    u = u.replaceAll('-', '&minus;')
    return u;
}

function calculatioCalcResult(crInArray) {
    var crProcArray = crInArray;
    if (-1 < crProcArray.indexOf("Error")) return "Error";
    for (; -1 < crProcArray.indexOf("EXP");) {
        var crFDTemp = crProcArray.indexOf("EXP");
        if (isNaN(crProcArray[crFDTemp + 1])) return "Error";
        (crProcArray[crFDTemp - 1] = crProcArray[crFDTemp - 1] * Math.pow(10, crProcArray[crFDTemp + 1])), crProcArray.splice(crFDTemp, 2);
    }
    for (i = 0; i < crProcArray.length; i++)
        "pi" == crProcArray[i] && (crProcArray[i] = Math.PI), "e" == crProcArray[i] && (crProcArray[i] = Math.E), "(" == crProcArray[i] && (crProcArray.splice(i, 1), i--), ")" == crProcArray[i] && (crProcArray.splice(i, 1), i--);
    for (i = 0; i < crProcArray.length; i++)
        if (((A = crProcArray[i]), "sin" == A || "cos" == A || "tan" == A || "asin" == A || "acos" == A || "atan" == A || "ln" == A || "log" == A || "3x" == A || "sqrt" == A)) {
            if (!(i + 1 < crProcArray.length)) return "Error";
            if (((B = crProcArray[i + 1]), isNaN(B))) return "Error";
            if ("sin" == A)
                if ("degree" == calculatioDegreeRadians) {
                    var tempV = Math.abs(B % 180);
                    crProcArray[i] = tempV < 1e-13 ? 0 : Math.sin((B / 180) * Math.PI);
                } else {
                    var tempV = Math.abs(B / Math.PI - Math.floor(B / Math.PI)) * Math.PI;
                    crProcArray[i] = tempV < 1e-13 ? 0 : Math.sin(B);
                }
            else if ("cos" == A)
                if ("degree" == calculatioDegreeRadians) {
                    var tempV = Math.abs(B % 180);
                    Math.abs(tempV - 90) < 1e-13 ? (crProcArray[i] = 0) : (crProcArray[i] = Math.cos((B / 180) * Math.PI));
                } else {
                    var tempV = Math.abs(B / Math.PI - Math.floor(B / Math.PI)) * Math.PI;
                    Math.abs(tempV - Math.PI / 2) < 1e-13 ? (crProcArray[i] = 0) : (crProcArray[i] = Math.cos(B));
                }
            else if ("tan" == A)
                if ("degree" == calculatioDegreeRadians) {
                    var tempV = Math.abs(B % 180);
                    tempV < 1e-13 ? (crProcArray[i] = 0) : Math.abs(tempV - 90) < 1e-13 ? (crProcArray[i] = "Error") : (crProcArray[i] = Math.tan((B / 180) * Math.PI));
                } else {
                    var tempV = Math.abs(B / Math.PI - Math.floor(B / Math.PI)) * Math.PI;
                    tempV < 1e-13 ? (crProcArray[i] = 0) : Math.abs(tempV - Math.PI / 2) < 1e-13 ? (crProcArray[i] = "Error") : (crProcArray[i] = Math.tan(B));
                }
            else
                "asin" == A
                    ? (crProcArray[i] = "degree" == calculatioDegreeRadians ? (180 * Math.asin(B)) / Math.PI : Math.asin(B))
                    : "acos" == A
                    ? (crProcArray[i] = "degree" == calculatioDegreeRadians ? (180 * Math.acos(B)) / Math.PI : Math.acos(B))
                    : "atan" == A
                        ? (crProcArray[i] = "degree" == calculatioDegreeRadians ? (180 * Math.atan(B)) / Math.PI : Math.atan(B))
                        : "ln" == A
                            ? (crProcArray[i] = Math.log(B))
                            : "log" == A
                                ? (crProcArray[i] = Math.log(B) / Math.LN10)
                                : "3x" == A
                                    ? B < 0
                                        ? (crProcArray[i] = -1 * Math.pow(-1 * B, 1 / 3))
                                        : (crProcArray[i] = Math.pow(B, 1 / 3))
                                    : "sqrt" == A && (crProcArray[i] = Math.sqrt(B));
            crProcArray.splice(i + 1, 1);
        }
    for (; -1 < crProcArray.indexOf("1/x") || -1 < crProcArray.indexOf("pc") || -1 < crProcArray.indexOf("n!") || -1 < crProcArray.indexOf("x3") || -1 < crProcArray.indexOf("x2");) {
        var j = crProcArray.indexOf("1/x");
        j < 0 && (j = 1e8);
        var k = crProcArray.indexOf("pc");
        -1 < k && k < j && (j = k),
            (k = crProcArray.indexOf("n!")),
        -1 < k && k < j && (j = k),
            (k = crProcArray.indexOf("x3")),
        -1 < k && k < j && (j = k),
            (k = crProcArray.indexOf("x2")),
        -1 < k && k < j && (j = k),
            "1/x" == crProcArray[j]
                ? ((crProcArray[j - 1] = 1 / crProcArray[j - 1]), crProcArray.splice(j, 1))
                : "pc" == crProcArray[j]
                ? ((crProcArray[j - 1] = 0.01 * crProcArray[j - 1]), crProcArray.splice(j, 1))
                : "n!" == crProcArray[j]
                    ? ((crProcArray[j - 1] = calculatioFactorial(crProcArray[j - 1])), crProcArray.splice(j, 1))
                    : "x3" == crProcArray[j]
                        ? ((crProcArray[j - 1] = crProcArray[j - 1] * crProcArray[j - 1] * crProcArray[j - 1]), crProcArray.splice(j, 1))
                        : "x2" == crProcArray[j] && ((crProcArray[j - 1] = crProcArray[j - 1] * crProcArray[j - 1]), crProcArray.splice(j, 1));
    }
    if (isNaN(crProcArray[crProcArray.length - 1])) return "Error";
    for (; -1 < crProcArray.lastIndexOf("pow") || -1 < crProcArray.lastIndexOf("apow") || -1 < crProcArray.lastIndexOf("ex") || -1 < crProcArray.indexOf("10x");) {
        var j = crProcArray.lastIndexOf("pow"),
            k = crProcArray.lastIndexOf("apow");
        if ((j < k && (j = k), (k = crProcArray.lastIndexOf("ex")), j < k && (j = k), (k = crProcArray.lastIndexOf("10x")), j < k && (j = k), "10x" == crProcArray[j]))
            "neg" == crProcArray[j + 1] ? ((crProcArray[j] = Math.pow(10, -1 * crProcArray[j + 2])), crProcArray.splice(j + 1, 2)) : ((crProcArray[j] = Math.pow(10, crProcArray[j + 1])), crProcArray.splice(j + 1, 1));
        else if ("ex" == crProcArray[j])
            "neg" == crProcArray[j + 1] ? ((crProcArray[j] = Math.pow(Math.E, -1 * crProcArray[j + 2])), crProcArray.splice(j + 1, 2)) : ((crProcArray[j] = Math.pow(Math.E, crProcArray[j + 1])), crProcArray.splice(j + 1, 1));
        else if ("pow" == crProcArray[j])
            "neg" == crProcArray[j + 1]
                ? (alert(crProcArray[j - 1] + " / " + crProcArray[j] + " / " + crProcArray[j + 1] + " / " + crProcArray[j + 2] + " / "),
                    (crProcArray[j - 1] = Math.pow(crProcArray[j - 1], -1 * crProcArray[j + 2])),
                    alert(crProcArray[j - 1]),
                    crProcArray.splice(j, 3))
                : ((crProcArray[j - 1] = Math.pow(crProcArray[j - 1], crProcArray[j + 1])), crProcArray.splice(j, 2));
        else if ("apow" == crProcArray[j])
            if ("neg" == crProcArray[j + 1]) {
                if (crProcArray[j + 2] - Math.round(crProcArray[j + 2]) == 0)
                    if (crProcArray[j - 1] < 0) {
                        if (crProcArray[j + 2] % 2 == 0) return "Error";
                        crProcArray[j - 1] = -1 * Math.pow(-1 * crProcArray[j - 1], 1 / crProcArray[j + 2]);
                    } else crProcArray[j - 1] = Math.pow(crProcArray[j - 1], 1 / crProcArray[j + 2]);
                else crProcArray[j - 1] = Math.pow(crProcArray[j - 1], 1 / crProcArray[j + 2]);
                crProcArray.splice(j, 3);
            } else {
                if (crProcArray[j + 1] - Math.round(crProcArray[j + 1]) == 0)
                    if (crProcArray[j - 1] < 0) {
                        if (crProcArray[j + 1] % 2 == 0) return "Error";
                        crProcArray[j - 1] = -1 * Math.pow(-1 * crProcArray[j - 1], 1 / crProcArray[j + 1]);
                    } else crProcArray[j - 1] = Math.pow(crProcArray[j - 1], 1 / crProcArray[j + 1]);
                else crProcArray[j - 1] = Math.pow(crProcArray[j - 1], 1 / crProcArray[j + 1]);
                crProcArray.splice(j, 2);
            }
    }
    for (i = 1; i < crProcArray.length; i++) (A = crProcArray[i]), (B = crProcArray[i - 1]), "neg" != B || isNaN(A) || ((crProcArray[i] = -1 * A), crProcArray.splice(i - 1, 1));
    if (crProcArray.length < 2) return crProcArray[0];
    var crDFOut = "";
    for (i = 0; i < crProcArray.length; i++) isNaN(crProcArray[i]) ? ("neg" == crProcArray[i] ? (crDFOut += "-") : (crDFOut += crProcArray[i])) : (crDFOut += "(" + crProcArray[i] + ")");
    try {
        return eval(crDFOut);
    } catch (r) {
        return "Error";
    }
}

function calculatioFactorial(r) {
    var a = Math.round(r);
    if (Math.abs(a - r) < 1e-11) {
        if (a < 0 || 200 < a) return "Error";
        var c = 1;
        for (i = 1; i <= a; ++i) c *= i;
        return c;
    }
    return calculatioFactorialD(parseFloat(r));
}

function calculatioFactorialD(r) {
    if (r < -1) return Math.PI / (Math.sin(Math.PI * (r + 1)) * calculatioFactorialD(-1 - r));
    for (
        var a = [
                57.15623566586292,
                -59.59796035547549,
                14.136097974741746,
                -0.4919138160976202,
                3399464998481189e-20,
                4652362892704858e-20,
                -9837447530487956e-20,
                0.0001580887032249125,
                -0.00021026444172410488,
                0.00021743961811521265,
                -0.0001643181065367639,
                8441822398385275e-20,
                -26190838401581408e-21,
                36899182659531625e-22,
            ],
            c = 0.9999999999999971,
            e = 0;
        e < 14;
        e++
    )
        c += a[e] / (r + e + 2);
    return Math.exp((r + 1.5) * Math.log(r + 6.2421875) - r - 6.2421875 + Math.log((2.5066282746310007 * c) / (r + 1)));
}

function calculatioValidateInputs() {
    var r = calculatioCombineNumbers();
    for (i = 0; i < r.length; i++)
        if (((A = r[i] + " "), (B = r[i - 1] + " "), -1 < A.indexOf("."))) {
            if (-1 < B.indexOf(".")) return 1;
            if (((A = A.substring(A.indexOf(".") + 1)), -1 < A.indexOf("."))) return 1;
        }
    for (i = 0; i < r.length; i++) {
        if (((A = r[i]), (B = r[i - 1]), (C = r[i - 2]), !(("+" != A && "-" != A && "*" != A && "/" != A) || ("+" != B && "-" != B && "*" != B && "/" != B)))) return 1;
        if (("1/x" == A || "pc" == A || "n!" == A || "x3" == A || "x2" == A || ")" == A || "apow" == A || "pow" == A || "*" == A || "/" == A || "+" == A || "-" == A || "EXP" == A || "+/-" == A) && "neg" == B) return 1;
        if (("neg" == B && (B = C), "(" == B && ("+" == A || "-" == A || "*" == A || "/" == A || ")" == A || "pc" == A || "n!" == A || "+/-" == A || "EXP" == A || "pow" == A || "apow" == A || "1/x" == A || "x3" == A || "x2" == A)))
            return 1;
        if (
            ")" == A &&
            ("+" == B ||
                "-" == B ||
                "*" == B ||
                "/" == B ||
                "sin" == B ||
                "cos" == B ||
                "tan" == B ||
                "asin" == B ||
                "acos" == B ||
                "atan" == B ||
                "pow" == B ||
                "ex" == B ||
                "10x" == B ||
                "apow" == B ||
                "3x" == B ||
                "sqrt" == B ||
                "ln" == B ||
                "log" == B ||
                "(" == B ||
                "EXP" == B)
        )
            return 1;
        if ("EXP" == A && isNaN(B)) return 1;
        if ("EXP" == B && "neg" != A) {
            if (isNaN(A)) return 1;
            if (-1 < A.indexOf(".")) return 1;
        }
        if (
            !(
                ("x3" != A && "x2" != A && "apow" != A && "pow" != A) ||
                ("sin" != B &&
                    "cos" != B &&
                    "tan" != B &&
                    "asin" != B &&
                    "acos" != B &&
                    "atan" != B &&
                    "ex" != B &&
                    "10x" != B &&
                    "ln" != B &&
                    "log" != B &&
                    "3x" != B &&
                    "sqrt" != B &&
                    "(" != B &&
                    "*" != B &&
                    "/" != B &&
                    "+" != B &&
                    "-" != B)
            )
        )
            return 1;
        if (!(("x3" != A && "x2" != A && "apow" != A && "pow" != A) || ("apow" != B && "pow" != B))) return 1;
        if (
            !(
                ("sin" != B &&
                    "cos" != B &&
                    "tan" != B &&
                    "asin" != B &&
                    "acos" != B &&
                    "atan" != B &&
                    "ex" != B &&
                    "10x" != B &&
                    "ln" != B &&
                    "log" != B &&
                    "3x" != B &&
                    "sqrt" != B &&
                    "(" != B &&
                    "apow" != B &&
                    "pow" != B &&
                    "EXP" != B &&
                    "*" != B &&
                    "/" != B &&
                    "+" != B &&
                    "-" != B) ||
                ("1/x" != A && "pc" != A && "n!" != A && "x3" != A && "x2" != A && ")" != A && "apow" != A && "pow" != A && "*" != A && "/" != A && "+" != A && "-" != A && "EXP" != A)
            )
        )
            return 1;
    }
    var a = 0,
        c = 0;
    for (i = 0; i < r.length; i++)
        if (((A = r[i]), (B = r[i - 1]), ")" == A && c++, ("sin" != B && "cos" != B && "tan" != B && "asin" != B && "acos" != B && "atan" != B && "ln" != B && "log" != B && "3x" != B && "sqrt" != B && "(" != B) || a++, a < c)) return 1;
    return 0;
}

function calculatioRemoveZero(r) {
    for (calculatioRZOut = r + ""; 1 < calculatioRZOut.length && "0" == calculatioRZOut.substring(0, 1) && "." != calculatioRZOut.substring(1, 2);) calculatioRZOut = calculatioRZOut.substring(1);
    return calculatioRZOut;
}

function calculatioCombineNumbers() {
    for (i = 1; i < calculatioCInputs.length; i++)
        "-" == calculatioCInputs[i] &&
        ((A = calculatioCInputs[i - 1]),
        ("sin" != A &&
            "cos" != A &&
            "tan" != A &&
            "asin" != A &&
            "acos" != A &&
            "atan" != A &&
            "ex" != A &&
            "10x" != A &&
            "ln" != A &&
            "log" != A &&
            "(" != A &&
            "apow" != A &&
            "pow" != A &&
            "*" != A &&
            "/" != A &&
            "+" != A &&
            "-" != A &&
            "EXP" != A &&
            "3x" != A &&
            "sqrt" != A) ||
        (calculatioCInputs[i] = "neg")),
        "+/-" == calculatioCInputs[i] && ((A = calculatioCInputs[i - 1]), "+/-" != A && "." != A && 1 != A && 2 != A && 3 != A && 4 != A && 5 != A && 6 != A && 7 != A && 8 != A && 9 != A && 0 != A && calculatioCInputs.splice(i, 1));
    var r = [],
        a = "";
    for (i = 0; i < calculatioCInputs.length; i++)
        (A = calculatioCInputs[i]),
            "+/-" == A || "." == A || 1 == A || 2 == A || 3 == A || 4 == A || 5 == A || 6 == A || 7 == A || 8 == A || 9 == A || 0 == A
                ? "." == A && "" == a
                ? (a = "0.")
                : (a += A)
                : "" == A || ("" != a && r.push(calculatioRemoveZero(a)), (a = ""), r.push(A));
    for ("" != a && r.push(calculatioRemoveZero(a)), a = "", i = 0; i < r.length; i++)
        if (((A = r[i] + ""), -1 < A.indexOf("+/-"))) {
            for (C = 0, F = ""; -1 < A.indexOf("+/-");) (TP = A.indexOf("+/-")), (F += A.substring(0, TP)), (A = A.substring(TP + 3)), C++;
            (F += A), (C %= 2), 1 == C && 0 < F.length && ("-" == F.substring(0, 1) ? (F = F.substring(1)) : (F = "-" + F)), (r[i] = F);
        }
    for (i = 1; i < r.length; i++) (A = r[i]), (B = r[i - 1]), "neg" == B && (isNaN(A) || ("-" == A.substring(0, 1) ? (r[i] = A.substring(1)) : (r[i] = "-" + A), r.splice(i - 1, 1), i--));
    return r;
}

function calculatioArrayProc(r, a, c, e) {
    var o = [];
    return (o = r.slice(0, a)).push(e), o.concat(r.slice(c));
}

function calculatioFmt(r) {
    var a = ("" + r).toLowerCase();
    if (isNaN(a)) return "Error ";
    if ("" == a) return "";
    if (0 <= a.indexOf("N") || (r == 2 * r && r == 1 + r)) return "Error ";
    var c = a.indexOf("e");
    if (0 <= c) {
        var e = parseInt(a.substring(c + 1, a.length));
        (a = parseFloat(a.substring(0, c))),
        11 < c && (c = 11),
            10 == (n = a < 0 ? parseFloat((a - 5e-12 + "").substring(0, c)) : parseFloat((a + 5e-12 + "").substring(0, c))) ? ((n = 1), e++) : -10 == n && ((n = -1), e++),
            (a = n + " &#215;10<sup>" + e + "</sup>");
    } else {
        var o = !1;
        r < 0 && ((r = -r), (o = !0));
        var t = Math.floor(r),
            i = r - t,
            l = calculatioAccuracy - ("" + t).length - 1,
            s = " 1000000000000000000".substring(1, l + 2) + "";
        s = "" == s || " " == s ? 1 : parseInt(s);
        var n = Math.floor(i * s + 0.5);
        (t = Math.floor(Math.floor(r * s + 0.5) / s)), (a = o ? "-" + t : "" + t);
        var A = "00000000000000" + n;
        for (c = (A = A.substring(A.length - l, A.length)).length - 1; 0 <= c && "0" == A.charAt(c);) --c;
        (A = A.substring(0, c + 1)), 0 <= c && (a += "." + A);
    }
    return a.replace('-', '&minus;');
}

export const setCalculatioCInputs = function (value) {
    calculatioCInputs = value
}

export const calc = function (r) {
    console.log(r, calculatioCInputs)
    if (
        (calculatioCInputs.length < 2 && calculatioNew && "1/x" != r && "x3" != r && "x2" != r && "apow" != r && "pow" != r && "*" != r && "/" != r && "+" != r && "-" != r && "neg" != calculatioCInputs[0] && (calculatioCInputs = [0]),
        "-" == r && calculatioCInputs.length < 2 && 0 == calculatioCInputs[0] && (calculatioCInputs[0] = "neg"),
        "MR" != r && (document.getElementById("scimrc").innerHTML = "MR"),
        "M+" == r)
    )
        "Error" == calculatioScreenV || isNaN(calculatioScreenV) || (calculatioMemoryV += parseFloat(calculatioScreenV + ""));
    else if ("M-" == r) "Error" == calculatioScreenV || isNaN(calculatioScreenV) || (calculatioMemoryV -= parseFloat(calculatioScreenV + ""));
    else if ("MR" == r)
        "MC" == document.getElementById("scimrc").innerHTML
            ? ((calculatioMemoryV = 0), (document.getElementById("scimrc").innerHTML = "MR"))
            : (1 < calculatioCInputs.length
            ? "pi" != (a = calculatioCInputs[calculatioCInputs.length - 1]) && "e" != a && "." != a && isNaN(a)
                ? calculatioCInputs.push(calculatioMemoryV)
                : 0 != calculatioMemoryV && (calculatioCInputs.push("*"), calculatioCInputs.push(calculatioMemoryV))
            : (calculatioCInputs = [calculatioMemoryV]),
                (calculatioScreenV = calculatioFinal(0)),
                (document.getElementById("scimrc").innerHTML = "MC"));
    else if ("ans" == r) {
        var a;
        1 < calculatioCInputs.length
            ? "pi" != (a = calculatioCInputs[calculatioCInputs.length - 1]) && "e" != a && "." != a && isNaN(a)
            ? "neg" != a && calculatioCInputs.push(calculatioPrevAns)
            : (calculatioCInputs.push("*"), calculatioCInputs.push(calculatioPrevAns))
            : (calculatioCInputs = [calculatioPrevAns]),
            (calculatioScreenV = calculatioFinal(0));
    } else if ("=" == r) {
        if (1 < calculatioCInputs.length) {
            "Error" == (calculatioScreenV = calculatioFinal(1)) || isNaN(calculatioScreenV) ? (calculatioCInputs = [0]) : ((calculatioCInputs = [calculatioScreenV]), (calculatioPrevAns = calculatioScreenV));
            return calculatioHistory
            // var c = "";
            // for (i = calculatioHistory.length - 1; 0 <= i; i--) c += "<div>" + calculatioHistory[i][0] + " = " + calculatioHistory[i][1] + "</div>";
            // document.getElementById("scihistory").innerHTML = c;
        }
        calculatioNew = !0;
    } else if ("C" == r) (calculatioCInputs = [0]), (document.getElementById("sciOutPut").innerHTML = "0"), (document.getElementById("sciInPut").innerHTML = ""), (calculatioNew = !0), (calculatioScreenV = 0);
    else if ("bk" == r) calculatioCInputs.splice(calculatioCInputs.length - 1, 1), 0 == calculatioCInputs.length && (calculatioCInputs = [0]), (calculatioScreenV = calculatioFinal(0));
    else {
        if ("RND" == r) {
            if (((r = Math.random() + ""), calculatioCInputs.length < 2)) "neg" == calculatioCInputs[0] ? calculatioCInputs.push(r) : (calculatioCInputs[0] = r);
            else {
                var e = calculatioCInputs[calculatioCInputs.length - 1];
                "+/-" != e && "." != e && isNaN(e) && calculatioCInputs.push(r);
            }
            calculatioNew = !1;
        } else calculatioCInputs.push(r);
        var o = calculatioValidateInputs(),
            t = calculatioCInputs.length;
        1 == o ? calculatioCInputs.splice(t - 1, 1) : -1 == o && calculatioCInputs.splice(t - 2, 1), (calculatioScreenV = calculatioFinal(0));
    }
}

const r = calc

export const calcKeydown = function (a) {
    106 == a.keyCode || (a.shiftKey && 56 == a.keyCode)
        ? r("*")
        : 107 == a.keyCode || (a.shiftKey && 187 == a.keyCode) || (a.shiftKey && 61 == a.keyCode)
        ? r("+")
        : a.shiftKey && 57 == a.keyCode
            ? r("(")
            : a.shiftKey && 48 == a.keyCode
                ? r(")")
                : a.shiftKey && 54 == a.keyCode
                    ? r("pow")
                    : a.shiftKey && 53 == a.keyCode
                        ? r("pc")
                        : a.shiftKey && 49 == a.keyCode
                            ? r("n!")
                            : a.ctrlKey || 83 != a.keyCode
                                ? a.ctrlKey || 67 != a.keyCode
                                    ? a.ctrlKey || 84 != a.keyCode
                                        ? a.ctrlKey || 76 != a.keyCode
                                            ? 80 == a.keyCode
                                                ? r("pi")
                                                : 69 == a.keyCode
                                                    ? r("e")
                                                    : 190 == a.keyCode || 110 == a.keyCode
                                                        ? r(".")
                                                        : 55 == a.keyCode || 103 == a.keyCode
                                                            ? r("7")
                                                            : 56 == a.keyCode || 104 == a.keyCode
                                                                ? r("8")
                                                                : 57 == a.keyCode || 105 == a.keyCode
                                                                    ? r("9")
                                                                    : 52 == a.keyCode || 100 == a.keyCode
                                                                        ? r("4")
                                                                        : 53 == a.keyCode || 101 == a.keyCode
                                                                            ? r("5")
                                                                            : 54 == a.keyCode || 102 == a.keyCode
                                                                                ? r("6")
                                                                                : 49 == a.keyCode || 97 == a.keyCode
                                                                                    ? r("1")
                                                                                    : 50 == a.keyCode || 98 == a.keyCode
                                                                                        ? r("2")
                                                                                        : 51 == a.keyCode || 99 == a.keyCode
                                                                                            ? r("3")
                                                                                            : 48 == a.keyCode || 96 == a.keyCode
                                                                                                ? r("0")
                                                                                                : 191 == a.keyCode || 111 == a.keyCode
                                                                                                    ? r("/")
                                                                                                    : 189 == a.keyCode || 109 == a.keyCode || 173 == a.keyCode
                                                                                                        ? r("-")
                                                                                                        : 66 == a.keyCode || 46 == a.keyCode || 8 == a.keyCode
                                                                                                            ? r("bk")
                                                                                                            : 27 == a.keyCode
                                                                                                                ? r("C")
                                                                                                                : 65 == a.keyCode
                                                                                                                    ? r("ans")
                                                                                                                    : (187 != a.keyCode && 13 != a.keyCode && 61 != a.keyCode)
                                            : r("ln")
                                        : r("tan")
                                    : r("cos")
                                : r("sin");
}